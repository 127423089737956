<script>
import AdminLayout from "@layouts/admin";
import { mapGetters } from "vuex";

export default {
    name: "Dashboard",
    components: {
        AdminLayout,
    },
    computed: {
        ...mapGetters("account", ["loggedInUser"]),
    },
};
</script>
<template lang="pug">
admin-layout
    .about
        h1 This is the admin dashboard
</template>
